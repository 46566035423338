import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'

import styled from 'styled-components'
import Cookies from 'cookies-js'
import Button from '../Button'
import { withTranslation } from '../../i18n'
import { auth } from '../../api/authorization'
import {Links, redirect_uri} from '../../constants/links'

import TextInput from '../TextInput'
import {
  ContentDiv, GlobalStyle, SocialLink, SocialIcon, CrmLinkWrapper, MobileCrmLinkWrapper
} from './styles'
import Router from 'next/router'

const CustomTextInput = styled(TextInput)`
  width: 338px;
  margin-bottom: 15px;
  @media (max-width: 1200px) and (min-width: 701px) {
    width: 268px;
    height: 40px;
    margin-bottom: 12px;
  }
  @media (max-width: 700px) {
    width: 270px;
  }
  @media (max-width: 330px) {
    width: 240px;
  }
`

const ResetButton = styled(Button)`
  margin-top: 15px;
`

const crmLink = 'https://crm.uralmusicnight.ru/'

const initalState = {
  loginEmail: '',
  loginPassword: '',
  email: '',
  password1: '',
  password2: '',
  pass1err: '',
  pass2err: '',
  regErrorMessage: [],
  authTab: true,
  registrationTab: false,
  forgotPasswordTab: false
}

@inject('store')
@observer
class UserModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired,
    checkAuthUser: PropTypes.bool
  }

  state = { ...initalState }

  hasEmail = false

  changeLoginEmail(email) {
    this.setState({ loginEmail: email })
  }

  componentDidUpdate(nextProps) {
    const { email } = nextProps.store.modal
    if (!this.hasEmail && email) {
      this.hasEmail = true
      this.changeLoginEmail(email)
    }
  }

  resetAll = () => {
    this.setState({ ...initalState })
  }

  resetErrors = () => {
    const { pass1err, pass2err, regErrorMessage } = this.state
    if (pass1err === '' && pass2err === '' && regErrorMessage.length === 0) return
    this.setState({
      pass1err: '',
      pass2err: '',
      regErrorMessage: []
    })
  }

  onLogin = () => {
    const { t, checkAuthUser } = this.props
    const { loginPassword, loginEmail } = this.state
    this.resetErrors()
    auth(
      {
        password: loginPassword,
        email: loginEmail
      },
      'login'
    )
      .then((res) => {
        Cookies.set('userToken', res.token)
        if (window && window.location.pathname.startsWith('/program/')) {
          this.props.store.setToken(res.token)
          this.props.store.updateUser()
        } else {
          this.props.store.setUser(res.user)
          this.props.store.setToken(res.token)
        }
        this.props.store.toggleSuccessModal(true, t('SUCCESS_LOGIN'))
        this.resetAll()
      })
      .catch((er) => {
        if (!er.errors) return
        er.errors.forEach((error) => {
          if (error.code === 'password') {
            this.setState({ pass1err: true })
          }
        })
        this.setState(prevState => ({ regErrorMessage: [...prevState.regErrorMessage, ...er.errors] }))
      })
  }

  onRegistration = () => {
    const { t } = this.props
    const { password1, password2, email } = this.state
    const credentials = {
      email,
      password1,
      password2
    }
    this.resetErrors()
    auth(credentials, 'registration')
      .then((res) => {
        Cookies.set('userToken', res.token)
        this.props.store.setUser(res.user)
        this.props.store.setToken(res.token)
        this.props.store.toggleSuccessModal(true, t('SUCCESS_REGISTER'))
        this.props.store.toggleLoginModal(false)
        this.resetAll()
      })
      .catch((er) => {
        if (!er.errors) return
        er.errors.forEach((error) => {
          if (error.code === 'password1') {
            this.setState({ pass1err: true })
          }
          if (error.code === 'password2') {
            this.setState({ pass2err: true })
          }
        })
        this.setState(prevState => ({ regErrorMessage: [...prevState.regErrorMessage, ...er.errors] }))
      })
  }

  onResetPassword = () => {
    const { email } = this.state
    const { toggleSuccessModal } = this.props.store
    const credentials = {
      email
    }
    this.resetErrors()
    auth(credentials, 'password_reset')
      .then((res) => {
        toggleSuccessModal(true, res.detail)
        this.resetAll()
      })
      .catch((er) => {
        if (!er.errors) return
        this.setState(prevState => ({ regErrorMessage: [...prevState.regErrorMessage, ...er.errors] }))
      })
  }

  render() {
    const {
      t,
      store: { modal, toggleLoginModal, user },
      checkAuthUser
    } = this.props
    const {
      authTab,
      forgotPasswordTab,
      registrationTab,
      email,
      password1,
      password2,
      pass1err,
      pass2err,
      regErrorMessage
    } = this.state

    return (
      <>
        <GlobalStyle />
        <ReactModal
          ariaHideApp={false}
          isOpen={modal.isOpen}
          onRequestClose={() => {
            toggleLoginModal(false)
            this.setState({
              email: '',
              password1: '',
              password2: '',
              pass1err: false,
              pass2err: false,
              regErrorMessage: [],
              authTab: true,
              registrationTab: false,
              forgotPasswordTab: false
            })
            if (!user.token && checkAuthUser) {
              Router.push(redirect_uri)
            }
          }}
          overlayClassName="UserModal_Overlay"
          className="UserModal_Content"
        >
          <ContentDiv registrationTab={registrationTab}>
            <div
              onClick={() => {
                this.setState((prevState) => {
                  this.resetErrors()
                  if (prevState.forgotPasswordTab) {
                    return {
                      registrationTab: true,
                      forgotPasswordTab: false,
                      authTab: false
                    }
                  }
                  return {
                    registrationTab: !prevState.registrationTab,
                    authTab: !prevState.authTab
                  }
                })
              }}
              role="presentation"
              className="otherTabButton"
            >
              <div className="whiteSpace" />
              <div className="title">{registrationTab ? t('AUTHORIZATION') : t('REGISTRATION')}</div>
            </div>
            <div className={`side ${registrationTab ? 'lightSide' : 'darkSide'}`}>
              {(authTab || forgotPasswordTab) && (
                <>
                  <div className="title">{t('WELCOME')}</div>
                  <div className="text">{modal.message}</div>
                  <Button
                    white
                    onPress={() => {
                      this.setState({
                        forgotPasswordTab: false,
                        authTab: false,
                        registrationTab: true,
                        pass1err: '',
                        pass2err: '',
                        regErrorMessage: []
                      })
                    }}
                  >
                    {t('SIGN_UP')}
                  </Button>

                  <CrmLinkWrapper>

                    <p className="title">{t('LINK_MESSAGE')}</p>
                    <Button white href={crmLink}>{t('LINK_AUTHORIZATION')}</Button>
                  </CrmLinkWrapper>

                </>
              )}
              {registrationTab && (
                <>
                  <div className="title">{t('REGISTRATION')}</div>
                  <div className="socialButtons">
                    <SocialLink href={Links.vkAuth} rel="noopener noreferrer">
                      <SocialIcon src="/static/images/vk-30-black.svg" />
                    </SocialLink>
                    {/* <SocialLink href={Links.fbAuth} rel="noopener noreferrer">
                      <SocialIcon src="/static/images/fb-30-black.svg" />
                    </SocialLink> */}
                  </div>
                  <p className="description">{t('SIGN_UP_WITH_EMAIL_MSG')}</p>
                  <CustomTextInput
                    ref={(ref) => {
                      if (ref) {
                        if (!this.regEmail) ref.focus()
                        this.regEmail = ref
                      }
                    }}
                    value={email}
                    onEnterPress={() => {
                      this.password1.focus()
                    }}
                    icon="envelope"
                    onChange={(value) => {
                      this.resetErrors()
                      this.state.email = value
                    }}
                    placeholder="Email"
                  />
                  <CustomTextInput
                    ref={ref => (this.password1 = ref)}
                    value={password1}
                    onEnterPress={() => {
                      this.password2.focus()
                    }}
                    onChange={(value) => {
                      this.resetErrors()
                      this.state.password1 = value
                    }}
                    placeholder={t('PASSWORD')}
                    password
                    error={!!pass1err}
                  />
                  <CustomTextInput
                    ref={ref => (this.password2 = ref)}
                    value={password2}
                    onEnterPress={this.onRegistration}
                    onChange={(value) => {
                      this.resetErrors()
                      this.state.password2 = value
                    }}
                    placeholder={t('PASSWORD_CONFIRMATION')}
                    password
                    error={!!pass2err}
                  />
                  <span className={`passwordHint ${regErrorMessage.length > 0 ? 'red' : ''}`}>
                    {regErrorMessage.map(error => error.text).join('\n') || t('PASSWORD_LENGTH_ERROR')}
                  </span>
                  <Button onPress={this.onRegistration}>{t('SIGN_UP')}</Button>
                </>
              )}
            </div>
            <div className={`side ${authTab || forgotPasswordTab ? 'lightSide' : 'darkSide'}`}>
              {registrationTab && (
                <>
                  <div className="title">{t('GLAD_TO_SEE_MSG')}</div>
                  <div className="text">{t('SIGN_IN_MSG')}</div>
                  <Button
                    white
                    onPress={() => {
                      this.setState({
                        forgotPasswordTab: false,
                        authTab: true,
                        registrationTab: false,
                        pass1err: '',
                        pass2err: '',
                        regErrorMessage: []
                      })
                    }}
                  >
                    {t('SIGN_IN')}
                  </Button>
                </>
              )}
              {authTab && (
                <>
                  <div className="title">{t('AUTHORIZATION')}</div>
                  <div className="socialButtons">
                    <SocialLink href={Links.vkAuth} rel="noopener noreferrer">
                      <SocialIcon src="/static/images/vk-30-black.svg" />
                    </SocialLink>
                    {/* <SocialLink href={Links.fbAuth} rel="noopener noreferrer">
                      <SocialIcon src="/static/images/fb-30-black.svg" />
                    </SocialLink> */}
                  </div>
                  <p className="description">{t('SIGN_IN_WITH_EMAIL_MSG')}</p>
                  <CustomTextInput
                    ref={(ref) => {
                      if (ref) {
                        if (!this.loginEmail) ref.focus()
                        this.loginEmail = ref
                      }
                    }}
                    value={this.state.loginEmail}
                    onChange={(value) => {
                      this.state.loginEmail = value
                    }}
                    icon="envelope"
                    placeholder="Email"
                    onEnterPress={() => {
                      this.loginPassword.focus()
                    }}
                  />
                  <CustomTextInput
                    ref={ref => (this.loginPassword = ref)}
                    value={this.state.loginPassword}
                    onChange={(value) => {
                      this.state.loginPassword = value
                    }}
                    placeholder={t('PASSWORD')}
                    password
                    error={!!pass1err}
                    onEnterPress={this.onLogin}
                  />
                  <button
                    className="passwordLink"
                    onClick={() => this.setState({ forgotPasswordTab: true, authTab: false })}
                  >
                    {t('FORGOT_PASSWORD')}
                  </button>
                  <Button onPress={this.onLogin}>{t('SIGN_IN')}</Button>
                  {regErrorMessage.length > 0 && (
                    <span className="passwordHint red">{regErrorMessage.map(error => error.text).join('\n')}</span>
                  )}
                </>
              )}
              {forgotPasswordTab && (
                <>
                  <div className="title">{t('reset-password:PASSWORD_RECOVERY')}</div>
                  <p className="description">{t('reset-password:HELP_WITH_RECOVERY_MSG')}</p>
                  <CustomTextInput
                    value={this.state.email}
                    onEnterPress={this.onResetPassword}
                    icon="envelope"
                    onChange={(value) => {
                      this.state.email = value
                    }}
                    placeholder="Email"
                  />
                  <ResetButton onPress={this.onResetPassword}>{t('reset-password:RESET_PASSWORD')}</ResetButton>
                  {regErrorMessage.length > 0 && (
                    <span className="passwordHint red">{regErrorMessage.map(error => error.text).join('\n')}</span>
                  )}
                </>
              )}
            </div>
          </ContentDiv>
          <MobileCrmLinkWrapper>
            <Button href={crmLink}>{t('FOR_PARTICIPANTS')}</Button>
          </MobileCrmLinkWrapper>
        </ReactModal>
      </>
    )
  }
}

export default withTranslation(['auth', 'reset-password'])(UserModal)
